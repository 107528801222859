import { Injectable } from '@angular/core';
import { Operation } from '@dougs/operations/dto';
import { AbstractAccountingOperationsStateService } from './abstract-accounting-operations.state.service';

@Injectable({
  providedIn: 'root',
})
export class NeedsAttentionOperationsStateService extends AbstractAccountingOperationsStateService {
  constructor() {
    super();

    this.operationsEventsService.validateOperation$.subscribe((operation: Operation) =>
      operation.needsAttention ? this.addOperationState(operation) : this.removeOperationState(operation),
    );
  }

  protected validated = false;
  protected needsAttention = true;

  protected shouldOperationBeAddedToState(operation: Operation): boolean {
    return operation.needsAttention;
  }
}
