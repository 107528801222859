import { Injectable } from '@angular/core';
import { Operation } from '@dougs/operations/dto';
import { AbstractAccountingOperationsStateService } from './abstract-accounting-operations.state.service';

@Injectable({
  providedIn: 'root',
})
export class ValidatedOperationsStateService extends AbstractAccountingOperationsStateService {
  constructor() {
    super();

    this.operationsEventsService.validateOperation$.subscribe((operation: Operation) => {
      if (operation.validated && !operation.needsAttention) {
        this.addOperationState(operation);
      } else if (!operation.validated || operation.needsAttention) {
        this.removeOperationState(operation);
      }
    });
  }

  protected validated = true;
  protected needsAttention = false;

  protected shouldOperationBeAddedToState(operation: Operation): boolean {
    return operation.validated && !operation.needsAttention;
  }
}
