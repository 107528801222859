import { Injectable } from '@angular/core';
import { Operation } from '@dougs/operations/dto';
import { AbstractOperationsStateService } from './abstract-operations-state.service';

@Injectable({
  providedIn: 'root',
})
export class PendingPointsWizardOperationsStateService extends AbstractOperationsStateService<void> {
  constructor() {
    super();
    this.operationsEventsService.validateOperation$.subscribe((operationUpdated: Operation) =>
      this.updateOperationState(operationUpdated),
    );
  }

  public addOperationState(operation: Operation) {
    super.addOperationState(operation);
  }

  resetState(): void {
    this.setState({
      operations: [],
    });
  }

  refreshOperations(): Promise<any> {
    // Useless for this state, operations are created manually
    return Promise.resolve(undefined);
  }

  async invalidateAndRemoveOperation(operation: Operation): Promise<boolean> {
    if (operation.validated) {
      await this.validateOperation(operation);
    }
    return await this.removeOperation(operation);
  }

  protected shouldOperationBeAddedToState(operation: Operation): boolean {
    // Useless for this state, operations are created manually and cannot be added from somewhere else bc it's a modal
    return false;
  }
}
