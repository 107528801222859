import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlashMessagesService } from '@dougs/ds';
import { X_HEADER } from './x-header.enum';

@Injectable()
export class HandleSuccessInterceptor implements HttpInterceptor {
  constructor(private readonly flashMessagesService: FlashMessagesService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          this.handleSuccess(event);
        }
      }),
    );
  }

  private handleSuccess(response: HttpResponse<unknown>): void {
    const userMessage: string = this.getUserMessage(response.headers);
    const userMessageType: string = this.getUserMessageType(response.headers);
    const userMessageDuration: number = this.getUserMessageDuration(response.headers);

    if (userMessage !== '' && userMessageType !== 'warning') {
      this.flashMessagesService.show(decodeURIComponent(userMessage), {
        type: 'success',
        timeout: userMessageDuration,
      });
    }
  }

  private getUserMessage(headers: HttpHeaders): string {
    return headers.get(X_HEADER.USER_MESSAGE) || '';
  }

  private getUserMessageType(headers: HttpHeaders): string {
    return headers.get(X_HEADER.MESSAGE_TYPE) || '';
  }

  private getUserMessageDuration(headers: HttpHeaders): number {
    return headers.get(X_HEADER.MESSAGE_DURATION) ? Number(headers.get(X_HEADER.MESSAGE_DURATION)) : 5000;
  }
}
