import { AccountingStats } from '../dto';

const getDefaults = (): AccountingStats => ({
  maxDate: new Date('2021-09-29'),
  minDate: new Date('2021-09-29'),
  periods: [],
});

export const getAccountingStatsMock = (p?: Partial<AccountingStats>): AccountingStats => ({
  ...getDefaults(),
  ...p,
});
