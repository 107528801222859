import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { AssociationSlotCandidates, Operation, OperationType } from '@dougs/operations/dto';
import { BreakdownHttpService } from '../http/breakdown.http';
import { LoadMoreOperationsService } from '../services/load-more-operations.service';
import { AbstractOperationsStateService } from './abstract-operations-state.service';

const MATCHING_INVOICES_LIMIT = 20;

@Injectable({
  providedIn: 'root',
})
export class ModalOperationsInvoicesStateService extends AbstractOperationsStateService<void> {
  constructor(
    private readonly loadMoreOperationsService: LoadMoreOperationsService,
    private readonly breakdownHttpService: BreakdownHttpService,
  ) {
    super();
    this.operationsEventsService.validateOperation$.subscribe((operation: Operation) => {
      this.updateOperationState(operation);
    });
  }

  async refreshOperations(
    operation: Operation,
    search: string,
    resetOffset = false,
    operationType: string[] = [],
    isInbound = true,
    candidates?: { associationType: string; slotType: string; breakdownId: number },
  ): Promise<void> {
    try {
      if (resetOffset) {
        this.loadMoreOperationsService.resetOperationOffsetByType(OperationType.MATCHING_INVOICES);
      }
      const offset: number = this.loadMoreOperationsService.getOperationOffsetByType(OperationType.MATCHING_INVOICES);
      const operations: Operation[] = await lastValueFrom(
        this.operationHttpService.getInvoices(
          operation.companyId,
          operation,
          MATCHING_INVOICES_LIMIT,
          offset * MATCHING_INVOICES_LIMIT,
          search,
          operationType,
          isInbound,
          candidates,
        ),
      );

      this.setState({
        operations,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async searchCreditNotes(
    operation: Operation,
    search: string,
    operationType: string[],
    isInbound: boolean,
    resetOffset = false,
    candidates?: { associationType: string; slotType: string; breakdownId: number },
  ): Promise<void> {
    try {
      if (resetOffset) {
        this.loadMoreOperationsService.resetOperationOffsetByType(OperationType.MATCHING_INVOICES);
      }
      const offset: number = this.loadMoreOperationsService.getOperationOffsetByType(OperationType.MATCHING_INVOICES);

      const operations: Operation[] = await lastValueFrom(
        this.operationHttpService.getCreditNotes(
          operation.companyId,
          operation,
          MATCHING_INVOICES_LIMIT,
          offset * MATCHING_INVOICES_LIMIT,
          operationType,
          isInbound,
          search,
          candidates,
        ),
      );

      this.setState({
        operations,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async loadMoreInvoices(
    operation: Operation,
    search: string,
    operationType: string[] = [],
    isInbound = true,
    candidates?: { associationType: string; slotType: string; breakdownId: number },
  ): Promise<void> {
    try {
      this.loadMoreOperationsService.incrementOperationOffsetByType(OperationType.MATCHING_INVOICES);
      const offset: number = this.loadMoreOperationsService.getOperationOffsetByType(OperationType.MATCHING_INVOICES);
      const operations: Operation[] = await lastValueFrom(
        this.operationHttpService.getInvoices(
          operation.companyId,
          operation,
          MATCHING_INVOICES_LIMIT,
          offset * MATCHING_INVOICES_LIMIT,
          search,
          operationType,
          isInbound,
          candidates,
        ),
      );

      this.setState({
        operations: [...this.state.operations, ...operations],
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async loadMoreCreditNotes(
    operation: Operation,
    operationType: string[],
    isInbound: boolean,
    search: string,
    candidates?: { associationType: string; slotType: string; breakdownId: number },
  ): Promise<void> {
    try {
      this.loadMoreOperationsService.incrementOperationOffsetByType(OperationType.MATCHING_INVOICES);
      const offset: number = this.loadMoreOperationsService.getOperationOffsetByType(OperationType.MATCHING_INVOICES);
      const operations: Operation[] = await lastValueFrom(
        this.operationHttpService.getCreditNotes(
          operation.companyId,
          operation,
          MATCHING_INVOICES_LIMIT,
          offset * MATCHING_INVOICES_LIMIT,
          operationType,
          isInbound,
          search,
          candidates,
        ),
      );

      this.setState({
        operations: [...this.state.operations, ...operations],
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  getBreakdownAssociationCandidates(
    companyId: number,
    operationId: number,
    breakdownId: number,
    associationName: string,
    slotName: string,
    offset = 0,
    search?: string,
  ): Observable<AssociationSlotCandidates> {
    return this.breakdownHttpService.getBreakdownAssociationCandidates(
      companyId,
      operationId,
      breakdownId,
      associationName,
      slotName,
      offset,
      search,
    );
  }

  async loadMoreCandidates(
    companyId: number,
    operationId: number,
    breakdownId: number,
    associationName: string,
    slotName: string,
    offset: number,
    search?: string,
  ): Promise<AssociationSlotCandidates | null> {
    try {
      return await lastValueFrom(
        this.breakdownHttpService.getBreakdownAssociationCandidates(
          companyId,
          operationId,
          breakdownId,
          associationName,
          slotName,
          offset,
          search,
        ),
      );
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  protected shouldOperationBeAddedToState(operation: Operation): boolean {
    return false;
  }
}
