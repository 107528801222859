import { Injectable } from '@angular/core';
import { SourceDocumentAttachment } from '@dougs/core/files';
import { Operation } from '@dougs/operations/dto';

@Injectable({ providedIn: 'root' })
export class OptimisticSourceDocumentOperationService {
  private readonly tmpSourceDocumentAttachmentsUuidMap: Map<number, string[]> = new Map<number, string[]>();

  addTmpUuids(operationId: number, uuids: string[]): void {
    this.tmpSourceDocumentAttachmentsUuidMap.set(operationId, [
      ...Array.from(this.tmpSourceDocumentAttachmentsUuidMap.get(operationId) ?? []),
      ...uuids,
    ]);
  }

  removeTmpUuids(operationId: number, uuids: string[]): void {
    this.tmpSourceDocumentAttachmentsUuidMap.set(operationId, [
      ...Array.from(this.tmpSourceDocumentAttachmentsUuidMap.get(operationId) ?? []).filter(
        (uuid) => !uuids.includes(uuid),
      ),
    ]);
  }

  clearTmpUuids(operationId: number): void {
    this.tmpSourceDocumentAttachmentsUuidMap.delete(operationId);
  }

  hasTmpUuid(operationId: number, uuid: string): boolean {
    return this.tmpSourceDocumentAttachmentsUuidMap.get(operationId)?.includes(uuid) ?? false;
  }

  getNewOperationToReplace(oldOperation: Operation, operation: Operation): Operation {
    const tmpSourceDocumentAttachments: SourceDocumentAttachment[] = this.getTmpSourceDocumentAttachments(
      oldOperation,
      operation,
    );
    const definitiveSourceDocumentAttachments: SourceDocumentAttachment[] = this.getDefinitiveSourceDocumentAttachments(
      oldOperation,
      operation,
    );
    const newOperation: Operation = {
      ...operation,
      sourceDocumentAttachments: [...definitiveSourceDocumentAttachments, ...tmpSourceDocumentAttachments],
    };
    return newOperation;
  }

  private getDefinitiveSourceDocumentAttachments(
    oldOperation: Operation,
    operation: Operation,
  ): SourceDocumentAttachment[] {
    return this.keepHasBeenUploadedNowSourceDocuments(operation, oldOperation).filter((sda) => !sda.isTmp);
  }

  private getTmpSourceDocumentAttachments(oldOperation: Operation, operation: Operation): SourceDocumentAttachment[] {
    const newTmpSourceDocuments: SourceDocumentAttachment[] =
      operation.sourceDocumentAttachments?.filter((sdaIterated) => !!sdaIterated.isTmp) || [];
    const oldTmpSourceDocuments: SourceDocumentAttachment[] =
      oldOperation.sourceDocumentAttachments?.filter(
        (sdaIterated) =>
          !!sdaIterated.isTmp && !newTmpSourceDocuments.some((newTmpSda) => newTmpSda.uuid === sdaIterated.uuid),
      ) || [];
    const finalTmpSourceDocumentAttachments: SourceDocumentAttachment[] = [
      ...oldTmpSourceDocuments,
      ...newTmpSourceDocuments,
    ].filter((tmpSda) => !!tmpSda.uuid && this.hasTmpUuid(operation.id, tmpSda.uuid));
    return finalTmpSourceDocumentAttachments;
  }

  private keepHasBeenUploadedNowSourceDocuments(
    newOperation: Operation,
    oldOperation: Operation,
  ): SourceDocumentAttachment[] {
    return (newOperation.sourceDocumentAttachments || []).map((sda) => {
      const oldSda: SourceDocumentAttachment | undefined = oldOperation.sourceDocumentAttachments?.find(
        (oldSdaIterated) => oldSdaIterated.id === sda.id,
      );
      return oldSda
        ? {
            ...sda,
            sourceDocument: { ...sda.sourceDocument, hasBeenUploadedNow: oldSda.sourceDocument.hasBeenUploadedNow },
          }
        : sda;
    });
  }
}
