import { Category } from '../dto/category.dto';
import { getCategoryGroupMock } from './category-group.mock';

const getDefaults = (): Category => ({
  id: 1,
  accountingNumber: 1,
  groupId: 1,
  hidden: false,
  wording: '',
  isInbound: false,
  isInvestment: false,
  keywords: [],
  resolvedAccountingNumbers: [],
  group: getCategoryGroupMock(),
  investment: {
    type: '',
    disableScrappedState: false,
    duration: 0,
    ratio: 0,
  },
});

export const getCategoryMock = (p?: Partial<Category>): Category => ({
  ...getDefaults(),
  ...p,
});
