import { AttachmentPost, SourceDocument, SourceDocumentAttachment } from '@dougs/core/files';
import { Currency } from '@dougs/synchronized-accounts/dto';
import { Profile } from '@dougs/user/dto';
import { AccountingLine } from './accounting-line.dto';
import { Breakdown, BreakdownPost } from './breakdown.dto';
import { Transaction } from './transaction.dto';

export type OperationStatusType =
  | 'pending'
  | 'paid'
  | 'partiallyPaid'
  | 'overPaid'
  | 'refunded'
  | 'pending:refunded'
  | 'paid:refunded'
  | 'partiallyPaid:refunded'
  | 'overPaid:refunded';

export interface Operation {
  id: number;
  type: string;
  amount: number;
  totalAmount: number;
  memo: string;
  name: string;
  isInbound: boolean;
  date: string;
  validated: boolean;
  transactionId: number;
  wording: string;
  excluded: boolean;
  exclusionReason: string;
  manuallyLocked: boolean;
  companyId: number;
  needsAttention: boolean;
  message: string;
  isDraft: boolean;
  autogenerated: boolean;
  investmentId: number;
  deleted: boolean;
  description: string;
  accountingSurveyId: number;
  detachDeleted: boolean;
  note: string;
  updatedAt: string;
  metadata: {
    paidAt?: Date;
    status?: string;
    isReversable: boolean;
    journal: string;
    allowedJournals: string[];
    hasStockManagementSoftware: boolean;
    isOperationInvalid: boolean;
    rawAmount?: number;
    isTmpInvoice?: boolean;
    date?: Date | string;
    salesChannelId?: number;
    error?: { messageCodeInstance: { userMessage: string } };
    predefinedCode?: string;
    shopifySynchStatus?: {
      status: 'success' | 'warning' | 'error';
      title: string;
      message: string;
      lastUpdatedDate: Date;
    };
    categorizationHelpStatus?: OperationCategorizationHelpStatus;
  };
  messengerId: number;
  validatedAt: string;
  flag: string;
  creatorId: number;
  createdAt: Date;
  sections: OperationSection[];
  breakdowns: Breakdown[];
  lockedByDate: boolean;
  transaction: Transaction;
  creator: {
    profile: Profile;
  };
  isMiscellaneous: boolean;
  isCustomerEditableMiscellaneous: boolean;
  isKilometricIndemnity: boolean;
  isBank: boolean;
  isInvoice: boolean;
  isExpense: boolean;
  isVendorInvoice: boolean;
  isInvoiceSupplier: boolean;
  isCashPayment: boolean;
  isDispatch: boolean;
  isAccountingSurveyMiscellaneous: boolean;
  hasVat: boolean;
  vatRate: number;
  vatAmount: number;
  isReconciliation: boolean;
  allowUnbalanced: boolean;
  isTotalAmountValid: boolean;
  messenger: {
    profile: Profile;
  };
  accountingLines: AccountingLine[];
  sourceDocumentCandidate?: SourceDocumentMatch;
  sourceDocumentAttachments: SourceDocumentAttachment[];
}

export interface OperationPost {
  accountId?: number;
  type?: string;
  date: string | Date;
  memo?: string;
  amount?: number;
  currency?: Currency;
  wording?: string;
  accountingSurveyId?: number;
  metadata?: OperationPostMetadata;
  attachments?: AttachmentPost[];
  breakdowns?: BreakdownPost[];
  isDraft?: boolean;
  isInbound?: boolean;
  isAccountingReport?: boolean;
}

export interface OperationPostMetadata {
  invoiceNumber?: number | null;
  isDispute?: boolean;
  realizedPercentage?: number | null;
  date?: Date | null;
  rawAmount?: number | null;
  isVatDisabled?: boolean;
  clientName?: string;
  supplierName?: string;
  status?: OperationStatusType[];
}

export interface OperationSection {
  id: string;
  label?: string;
  description: string;
  invertSign?: boolean;
  counterpart?: boolean;
}

export interface OperationTrackingParams {
  'Operation Id': number;
  'Operation Date': string;
  'Sale Channel Category': string;
  'Sale Channel Type': string;
}

export interface SourceDocumentMatch {
  id: number;
  sourceDocument: SourceDocument;
}

export enum OperationCategorizationHelpStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  ABORTED = 'aborted',
}
