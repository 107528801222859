import { AccountingLine } from '../dto';

const getDefaults = (): AccountingLine => ({
  companyId: 1,
  accountNumber: '1',
  amount: 1,
  categoryId: 1,
  date: '2023-04-19',
  cumulative: 1,
  fileCount: 1,
  id: 1,
  isCounterpart: false,
  isCredit: false,
  isLocked: false,
  journal: '',
  note: '',
  isLockedByDate: '',
  operationId: 1,
  memo: '',
  validated: false,
  wording: '',
  associationDescriptions: [],
  type: '',
});

export const getAccountingLineMock = (p?: Partial<AccountingLine>): AccountingLine => ({
  ...getDefaults(),
  ...p,
});
