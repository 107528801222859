import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { AccountingSearch } from '@dougs/operations/dto';

@Injectable({ providedIn: 'root' })
export class OperationsSearchService {
  private readonly hasCurrentSearchSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasCurrentSearch$: Observable<boolean> = this.hasCurrentSearchSubject.asObservable();

  private readonly currentSearchSubject: ReplaySubject<AccountingSearch> = new ReplaySubject<AccountingSearch>(1);
  public currentSearch$: Observable<AccountingSearch> = this.currentSearchSubject.asObservable();

  public setCurrentSearch(search: AccountingSearch): boolean {
    this.currentSearchSubject.next(search);
    const hasCurrentSearch: boolean = this.hasCurrentSearch(search);
    this.hasCurrentSearchSubject.next(hasCurrentSearch);
    return hasCurrentSearch;
  }

  private hasCurrentSearch(search: AccountingSearch): boolean {
    return !!(
      search &&
      (search.search ||
        search.amount ||
        search.date ||
        search.isInbound ||
        search.isNotInbound ||
        search.all ||
        search.autogenerated ||
        search.excludedOrDeleted ||
        search.ecommerceCollection ||
        (search.accountIds && search.accountIds.length > 0))
    );
  }
}
