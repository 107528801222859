import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { CompanyChanged } from '@dougs/core/socket';
import { StateService } from '@dougs/core/state';
import { Operation } from '@dougs/operations/dto';
import { UserStateService } from '@dougs/user/shared';
import { OperationHttpService } from '../http/operation.http';
import { OperationsEventsService } from '../services/operations-events.service';

@Injectable({
  providedIn: 'root',
})
export class SocketOperationsStateService extends StateService<void> {
  constructor(
    private readonly operationHttpService: OperationHttpService,
    private readonly operationsEventsService: OperationsEventsService,
    private readonly userStateService: UserStateService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async updateOperationFromSocket(companyChanged: CompanyChanged): Promise<void> {
    // TODO propagate delete with operationId on type === delete
    if (
      companyChanged.modelName === 'operation' &&
      companyChanged.type !== 'delete' &&
      this.userStateService.loggedInUser.id !== companyChanged.userId
    ) {
      try {
        const operation: Operation = await lastValueFrom(
          this.operationHttpService.getOperationById(companyChanged.companyId, Number(companyChanged.modelId)),
        );
        this.operationsEventsService.propagateValidateOperation(operation);
        this.operationsEventsService.propagateUpdateOperation(operation);
      } catch (e) {
        this.logger.error(e);
      }
    }
  }
}
