import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FlashMessagesService } from '@dougs/ds';
import { X_HEADER } from './x-header.enum';

@Injectable()
export class HandleErrorInterceptor implements HttpInterceptor {
  constructor(private readonly flashMessagesService: FlashMessagesService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => this.handleError(request, error)));
  }

  private handleError(request: HttpRequest<unknown>, error: HttpErrorResponse): Observable<never> {
    const userMessage: string = this.getUserMessage(error.headers, error.status);
    const duration: string | null = error.headers.get(X_HEADER.MESSAGE_DURATION);
    const silentUserMessageFromRequest: string | null = request.headers.get(X_HEADER.SILENT_USER_MESSAGE);
    const silentUserMessageFromResponse: string | null = error.headers.get(X_HEADER.SILENT_USER_MESSAGE);
    if (userMessage && !silentUserMessageFromRequest && !silentUserMessageFromResponse) {
      this.flashMessagesService.show(decodeURIComponent(userMessage), {
        type: 'error',
        timeout: duration ? +duration : 5000,
      });
    }

    return throwError(() => error);
  }

  private getUserMessage(headers: HttpHeaders, status: number): string {
    const userMessage: string | null = headers.get(X_HEADER.USER_MESSAGE);

    switch (status) {
      case 401:
        return userMessage || '';
      case 413:
        return 'Le fichier chargé est trop volumineux';
      default:
        return userMessage || 'Oups... une erreur est survenue. Nous sommes prévenus.';
    }
  }
}
