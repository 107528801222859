import { SourceDocumentAttachment, SourceDocumentType } from '@dougs/core/files';
import { generateUuidV4 } from '@dougs/core/utils';
import { SalesInvoice } from '@dougs/sales-invoice/dto';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';

export class SourceDocumentAttachmentUtils {
  static createSourceDocumentAttachmentFromSalesInvoice(salesInvoice: SalesInvoice): SourceDocumentAttachment {
    return {
      id: 0,
      isDeletable: true,
      sourceDocument: {
        id: salesInvoice.sourceDocumentId,
        type: SourceDocumentType.SALES_INVOICE,
        externalId: salesInvoice.id,
        companyId: salesInvoice.companyId,
        file: {
          id: salesInvoice.fileId,
          name: salesInvoice.fileName,
          url: salesInvoice.filePath,
        },
      },
    } as SourceDocumentAttachment;
  }

  static createSourceDocumentAttachmentFromVendorInvoice(vendorInvoice: VendorInvoice): SourceDocumentAttachment {
    return {
      id: 0,
      isDeletable: true,
      sourceDocument: {
        id: vendorInvoice.sourceDocumentId,
        type: SourceDocumentType.VENDOR_INVOICE,
        externalId: vendorInvoice.id,
        companyId: vendorInvoice.companyId,
        file: {
          name: vendorInvoice.fileName,
          url: vendorInvoice.filePath,
        },
      },
    } as SourceDocumentAttachment;
  }

  static createSourceDocumentAttachmentFromFile(file: File): SourceDocumentAttachment {
    return {
      id: 0,
      isDeletable: true,
      sourceDocument: {
        tempUuid: generateUuidV4(),
        id: 0,
        type: SourceDocumentType.OTHER,
        companyId: 0,
        file: {
          name: file.name,
          url: URL.createObjectURL(file),
        },
      },
    } as SourceDocumentAttachment;
  }
}
