import { Injectable } from '@angular/core';
import { endOfMonth, format } from 'date-fns';
import { AccountingSurvey } from '@dougs/accounting-survey/dto';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingLineDate, AccountingNumberRange, AccountingSearch, Operation } from '@dougs/operations/dto';
import { AbstractAccountingSurveyOperationsStateService } from './abstract-accounting-survey-operations.state.service';

const ACCOUNT_NUMBER = 508;

@Injectable({
  providedIn: 'root',
})
export class LoanTermAccountOperationsStateService extends AbstractAccountingSurveyOperationsStateService {
  constructor() {
    super();
  }

  protected shouldOperationBeAddedToState(operation: Operation): boolean {
    return false;
  }

  protected getOperationSearch(accountingSurvey: AccountingSurvey, accountingYear: AccountingYear): AccountingSearch {
    const accountingLineDate: AccountingLineDate = {
      minAccountingLineDate: format(endOfMonth(new Date(accountingYear.openingDate)), 'yyyy-MM-dd'),
      maxAccountingLineDate: format(endOfMonth(new Date(accountingYear.effectiveClosingDate)), 'yyyy-MM-dd'),
    };
    const accountNumberRanges: AccountingNumberRange[] = [
      {
        minAccountingNumber: ACCOUNT_NUMBER,
        maxAccountingNumber: ACCOUNT_NUMBER,
      },
    ];
    return {
      accountingLineDate,
      accountNumberRanges,
      autogenerated: false,
    };
  }
}
