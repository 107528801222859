import { Breakdown } from '../dto';

const getDefaults = (): Breakdown => ({
  id: 1,
  isInbound: false,
  associations: [],
  amount: 1,
  amountExcludingTaxesWithRecoverageRate: 1,
  associationData: {
    kilometers: undefined,
    partnerId: undefined,
  },
  categoryId: 1,
  companyId: 1,
  categoryWording: '',
  hasVat: false,
  createdAt: '',
  isCategoryEditable: false,
  vatRate: 1,
  isCounterpart: false,
  wording: '',
  isDeletable: false,
  isFee: false,
  isRefund: false,
  isVatAmountEditable: false,
  manualVatAmount: 1,
  metadata: {
    additionalWording: '',
    afterUnitAmountText: '',
    depreciationPercent: 0,
    amount: 0,
    unitPriceExcludingTaxes: 0,
    quantity: 0,
    wording: '',
  },
  operationId: 1,
  quantity: 1,
  resolvedCategoryId: 1,
  section: '',
  vatAmount: 1,
  unitAmount: 1,
});

export const getBreakdownMock = (p?: Partial<Breakdown>): Breakdown => ({
  ...getDefaults(),
  ...p,
});
