import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { ChangeHistory, Operation } from '@dougs/operations/dto';
import { OperationHistoryHttpService } from '../http/operation-history.http';

@Injectable({
  providedIn: 'root',
})
export class OperationHistoryStateService extends StateService<void> {
  constructor(
    private readonly operationHistoryHttpService: OperationHistoryHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async getOperationHistoryChanges(operation: Operation): Promise<ChangeHistory[]> {
    try {
      return await lastValueFrom(
        this.operationHistoryHttpService.getOperationChangesHistory(operation.companyId, operation.id),
      );
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }
}
