import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SourceDocumentAttachment, SourceDocumentType } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Operation } from '@dougs/operations/dto';
import { OperationHttpService } from '../http/operation.http';

@Injectable({
  providedIn: 'root',
})
export class StockOperationStateService extends StateService<Operation> {
  stockOperations$ = this.select((state) => state);

  constructor(
    private readonly operationHttpService: OperationHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async updateStockOperation(operation: Operation): Promise<Operation> {
    try {
      const stockOperationUpdated: Operation = await lastValueFrom(
        this.operationHttpService.updateOperation(operation),
      );
      this.setState(stockOperationUpdated);
      return stockOperationUpdated;
    } catch (e) {
      this.logger.error(e);
      this.setState({ ...this.state });
      return { ...this.state };
    }
  }

  async uploadStockOperationSourceDocumentAttachment(operation: Operation, file: File): Promise<void> {
    try {
      const operationUpdated: Operation = await lastValueFrom(
        this.operationHttpService.uploadSourceDocuments(operation, [file], SourceDocumentType.OTHER),
      );

      this.setState(operationUpdated);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deleteStockOperationSourceDocumentAttachment(
    operation: Operation,
    sourceDocumentAttachment: SourceDocumentAttachment,
  ): Promise<void> {
    try {
      const operationUpdated: Operation = await lastValueFrom(
        this.operationHttpService.detachSourceDocument(operation, sourceDocumentAttachment.id),
      );

      this.setState(operationUpdated);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getStockOperation(companyId: number, operationId: number): Promise<Operation | null> {
    try {
      this.setState(await lastValueFrom(this.operationHttpService.getOperationById(companyId, operationId)));

      return this.state;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }
}
