import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Category } from '@dougs/operations/dto';
import { CategoryHttpService } from '../http/category.http';

interface CategoryState {
  allCategories: Category[];
}

@Injectable({
  providedIn: 'root',
})
export class CategoryStateService extends StateService<CategoryState> {
  allCategories$: Observable<Category[]> = this.select((state) => state.allCategories);

  constructor(
    private readonly logger: LoggerService,
    private readonly categoryHttpService: CategoryHttpService,
  ) {
    super();
  }

  async getAllCategories(companyId: number): Promise<void> {
    try {
      this.setState({
        allCategories: await lastValueFrom(this.categoryHttpService.getAllCategories(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
      this.setState({
        allCategories: [],
      });
    }
  }

  async getCategoriesId(
    companyId: number,
    type: 'investment' | 'expense' | 'operation',
    operationDate?: string,
    isRefund = false,
    search?: string,
  ): Promise<number[]> {
    try {
      return await lastValueFrom(
        this.categoryHttpService.getCategoriesId(companyId, type, operationDate, isRefund, search),
      );
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  async getCategory(companyId: number, categoryId: number): Promise<Category | null> {
    try {
      return await lastValueFrom(this.categoryHttpService.getCategory(companyId, categoryId));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getOperationPreferredCategoriesId(
    companyId: number,
    breakdownId: number,
    operationId: number,
  ): Promise<number[]> {
    try {
      return await lastValueFrom(
        this.categoryHttpService.getOperationPreferredCategoriesId(companyId, breakdownId, operationId),
      );
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  async getOperationAvailableCategoriesId(
    companyId: number,
    breakdownId: number,
    operationId: number,
    isRefund: boolean,
    search: string,
  ): Promise<number[]> {
    try {
      return await lastValueFrom(
        this.categoryHttpService.getOperationAvailableCategoriesId(
          companyId,
          breakdownId,
          operationId,
          isRefund,
          search,
        ),
      );
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }
}
