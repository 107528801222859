import { AssociationSlotCandidates } from '../dto';

const getDefaults = (): AssociationSlotCandidates => ({
  descriptor: {
    askForSaleDate: false,
    fallbackToCreation: true,
    description: {
      long: '',
      action: '',
      gender: 'F',
      prefix: '',
      short: '',
      shortPlural: '',
    },
    candidateRecordFilter: null,
    defaultText: '',
    allowRental: false,
    additionalMessage: '',
    title: '',
    allowedRecordFilter: {
      type: '',
    },
    emptyText: '',
  },
  items: [],
  preferredItems: [],
});

export const getAssociationSlotCandidatesMock = (
  p?: Partial<AssociationSlotCandidates>,
): AssociationSlotCandidates => ({
  ...getDefaults(),
  ...p,
});
