import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Operation } from '@dougs/operations/dto';
import { AbstractOperationsStateService } from './abstract-operations-state.service';

@Injectable({
  providedIn: 'root',
})
export class ModalOperationStateService extends AbstractOperationsStateService<{
  currentOperation: Operation | null;
}> {
  constructor() {
    super();

    this.operationsEventsService.validateOperation$.subscribe((operation: Operation) => {
      this.updateOperationState(operation);
    });
  }

  public currentOperation$: Observable<Operation | null> = this.select((state) => state.currentOperation);

  async refreshOperations(): Promise<Operation[]> {
    return [];
  }

  protected shouldOperationBeAddedToState(): boolean {
    return false;
  }

  public resetCurrentOperationState(): void {
    this.setState({
      currentOperation: undefined,
    });
  }

  public updateCurrentOperationState(operation: Operation): void {
    this.setState({
      currentOperation: operation,
    });
  }

  public updateOperationState(operation: Operation): void {
    if (this.state?.currentOperation?.id === operation?.id) {
      this.setState({
        currentOperation: operation,
      });
    }
  }

  protected removeOperationState(operation: Operation): void {
    if (this.state?.currentOperation?.id === operation?.id) {
      this.setState({
        currentOperation: null,
      });
    }
  }
}
