import { AssociationSlot } from '../dto';

const getDefaults = (): AssociationSlot => ({
  description: {
    long: '',
    gender: 'F',
    prefix: '',
    short: '',
    action: '',
    shortPlural: '',
  },
  key: '',
  type: 'enum',
  isAssignable: true,
  name: '',
  isEditable: true,
  modelName: '',
  selectedItem: {
    description: '',
    angular2Description: '',
    endDate: undefined,
    iconUrl: '',
    inlineIconUrl: '',
    inlineLabel: '',
    isEnded: undefined,
    label: '',
    value: 1,
  },
});

export const getAssociationSlotMock = (p?: Partial<AssociationSlot>): AssociationSlot => ({
  ...getDefaults(),
  ...p,
});
