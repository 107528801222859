import { Injectable } from '@angular/core';
import { HttpCacheService } from '@dougs/core/http-cache';
import { Category } from '@dougs/operations/dto';

@Injectable({
  providedIn: 'root',
})
export class CategoryCacheService extends HttpCacheService<Category[]> {
  CACHE_DURATION_IN_SECONDS = 3600;
}
