import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AccountingSearch, Operation } from '@dougs/operations/dto';
import { AbstractOperationsStateService } from './abstract-operations-state.service';

@Injectable({
  providedIn: 'root',
})
export class ModalOperationsStateService extends AbstractOperationsStateService<void> {
  constructor() {
    super();
    this.operationsEventsService.validateOperation$.subscribe((operation: Operation) => {
      this.updateOperationState(operation);
    });
  }

  async refreshOperations(companyId: number, search: AccountingSearch, validated = false): Promise<void> {
    try {
      const operations: Operation[] = await this.getModalOperations(companyId, search, validated);
      this.setState({
        operations,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getModalOperations(
    companyId: number,
    search: AccountingSearch,
    validated = false,
    offset = 0,
  ): Promise<Operation[]> {
    try {
      if (search.useVatOperationsCustomRoute) {
        return await lastValueFrom(
          this.operationHttpService.getVatOperations(
            companyId,
            this.LIMIT,
            offset * this.LIMIT,
            validated,
            false,
            search,
            !validated,
          ),
        );
      }
      return await lastValueFrom(
        this.operationHttpService.getOperations(
          companyId,
          this.LIMIT,
          offset * this.LIMIT,
          validated,
          false,
          search,
          !validated,
        ),
      );
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  async loadMoreOperations(
    companyId: number,
    search: AccountingSearch,
    offset: number,
    validated = false,
  ): Promise<void> {
    try {
      const newOperations: Operation[] = await this.getModalOperations(companyId, search, validated, offset);
      this.setState({
        operations: [...this.state.operations, ...newOperations],
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  public resetStateOperations(): void {
    if (this.state.operations?.length) {
      this.setState({
        operations: [],
      });
    }
  }

  protected shouldOperationBeAddedToState(operation: Operation): boolean {
    return false;
  }
}
