import { Injectable } from '@angular/core';
import { AccountingSurvey } from '@dougs/accounting-survey/dto';
import { AccountingSearch, Operation } from '@dougs/operations/dto';
import { AbstractAccountingSurveyOperationsStateService } from './abstract-accounting-survey-operations.state.service';

@Injectable({
  providedIn: 'root',
})
export class PendingPointsOperationsStateService extends AbstractAccountingSurveyOperationsStateService {
  constructor() {
    super();
  }

  protected shouldOperationBeAddedToState(operation: Operation): boolean {
    return (
      !!this.accountingSurvey &&
      operation.accountingSurveyId === this.accountingSurvey.id &&
      this.accountingSurvey.data.pendingPoints.data.operationType?.includes(operation.type) &&
      !operation.autogenerated
    );
  }

  protected getOperationSearch(accountingSurvey: AccountingSurvey): AccountingSearch {
    return {
      types: accountingSurvey.data.pendingPoints.data.operationType,
      accountingSurveyId: accountingSurvey.id,
      autogenerated: false,
      ignoreIsDraft: true,
    };
  }
}
