import { Injectable } from '@angular/core';
import { LoggerService } from '@dougs/core/logger';
import { OperationType } from '@dougs/operations/dto';

@Injectable({
  providedIn: 'root',
})
export class LoadMoreOperationsService {
  private currentNotValidatedOperationOffset = 0;
  private currentValidatedOperationOffset = 0;
  private currentNeedAttentionOperationsOffset = 0;
  private currentMatchingInvoicesOffset = 0;

  constructor(private readonly logger: LoggerService) {}

  public getOperationOffsetByType(type: OperationType): number {
    switch (type) {
      case OperationType.VALIDATED:
        return this.currentValidatedOperationOffset;
      case OperationType.NEEDS_ATTENTION:
        return this.currentNeedAttentionOperationsOffset;
      case OperationType.NOT_VALIDATED:
        return this.currentNotValidatedOperationOffset;
      case OperationType.MATCHING_INVOICES:
        return this.currentMatchingInvoicesOffset;
      default:
        this.logger.error('Operation type is not valid');
        return 0;
    }
  }

  public incrementOperationOffsetByType(type: OperationType): void {
    switch (type) {
      case OperationType.VALIDATED:
        this.currentValidatedOperationOffset += 1;
        break;
      case OperationType.NEEDS_ATTENTION:
        this.currentNeedAttentionOperationsOffset += 1;
        break;
      case OperationType.NOT_VALIDATED:
        this.currentNotValidatedOperationOffset += 1;
        break;
      case OperationType.MATCHING_INVOICES:
        this.currentMatchingInvoicesOffset += 1;
        break;
      default:
        this.logger.error('Operation type is not valid');
        break;
    }
  }

  public resetOperationOffsetByType(type: OperationType): void {
    switch (type) {
      case OperationType.VALIDATED:
        this.currentValidatedOperationOffset = 0;
        break;
      case OperationType.NEEDS_ATTENTION:
        this.currentNeedAttentionOperationsOffset = 0;
        break;
      case OperationType.NOT_VALIDATED:
        this.currentNotValidatedOperationOffset = 0;
        break;
      case OperationType.MATCHING_INVOICES:
        this.currentMatchingInvoicesOffset = 0;
        break;
      default:
        this.logger.error('Operation type is not valid');
        break;
    }
  }
}
