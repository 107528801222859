import { Injectable } from '@angular/core';
import { SourceDocumentAttachment, SourceDocumentType } from '@dougs/core/files';
import { Breakdown, Category, Operation, OperationSection } from '@dougs/operations/dto';
import { UserStateService } from '@dougs/user/shared';

@Injectable({
  providedIn: 'root',
})
export class OperationService {
  constructor(private readonly userStateService: UserStateService) {}

  isCategoryEditable(operation: Operation, breakdown?: Breakdown): boolean | undefined {
    if (operation) {
      const mainBreakdown: Breakdown | null = breakdown || this.getMainBreakdown(operation);
      return (
        !this.isReadOnly(operation) &&
        !!mainBreakdown &&
        !operation.isKilometricIndemnity &&
        mainBreakdown?.isCategoryEditable
      );
    }
  }

  public getSelectedCategory(mainBreakdown: Breakdown | null): Category {
    return {
      id: mainBreakdown?.categoryId || 0,
      wording: mainBreakdown?.categoryWording || 'Catégories multiples',
    } as Category;
  }

  isReadOnly(operation: Operation): boolean {
    return operation.validated || this.isLocked(operation);
  }

  isLocked(operation: Operation): boolean {
    return (
      operation.manuallyLocked ||
      operation.lockedByDate ||
      (operation.isMiscellaneous &&
        !operation.isCustomerEditableMiscellaneous &&
        !this.userStateService.loggedInUser.isAccountantOrAdmin)
    );
  }

  isOperationEditable(operation: Operation): boolean {
    return (
      (operation.type === 'kilometricIndemnity' ||
        operation.type === 'expense' ||
        operation.type === 'vendorInvoice') &&
      !operation.validated
    );
  }

  isOperationDeletable(operation: Operation): boolean {
    if (
      operation.type === 'kilometricIndemnity' ||
      operation.type === 'expense' ||
      operation.type === 'vendorInvoice' ||
      operation.validated
    ) {
      return false;
    }

    if (operation.isBank) {
      return operation.transaction?.isManual ? this.userStateService.loggedInUser.isAccountantOrAdmin : false;
    } else if (operation.isDispatch || operation.isInvoice) {
      return false;
    }

    return true;
  }

  isDefaultOperation(operation: Operation): boolean {
    return (
      operation.isBank ||
      operation.isInvoiceSupplier ||
      operation.isExpense ||
      operation.isVendorInvoice ||
      operation.isCashPayment ||
      operation.isInvoice ||
      operation.isDispatch ||
      (operation.isAccountingSurveyMiscellaneous && !operation.type.includes('stock'))
    );
  }

  getMainBreakdown(operation: Operation): Breakdown | null {
    const breakdowns: Breakdown[] = operation.breakdowns.filter(
      (breakdown) => !breakdown.isFee && !breakdown.isCounterpart,
    );

    return breakdowns.length === 1 ? breakdowns[0] : null;
  }

  isRefund(operation: Operation): boolean {
    return !!this.getMainBreakdown(operation)?.isRefund;
  }

  getCounterPartBreakdown(operation: Operation): Breakdown | null {
    const breakdowns: Breakdown[] = operation.breakdowns.filter((breakdown) => breakdown.isCounterpart);

    return breakdowns.length === 1 ? breakdowns[0] : null;
  }

  getFeeBreakdown(operation: Operation): Breakdown | null {
    const breakdowns: Breakdown[] = operation.breakdowns.filter((breakdown) => breakdown.isFee);

    return breakdowns.length === 1 ? breakdowns[0] : null;
  }

  getAmountExcludingFee(operation: Operation): number {
    const feeBreakdown: Breakdown | null = this.getFeeBreakdown(operation);
    const isInbound: number = operation.isInbound !== feeBreakdown?.isInbound ? -1 : 1;
    return feeBreakdown ? operation.amount - feeBreakdown.amount * isInbound : operation.amount;
  }

  getTotalAmountExcludingFee(operation: Operation): number {
    const feeBreakdown: Breakdown | null = this.getFeeBreakdown(operation);
    const isInbound: number = operation.isInbound !== feeBreakdown?.isInbound ? -1 : 1;
    return feeBreakdown ? operation.totalAmount - feeBreakdown.amount * isInbound : operation.totalAmount;
  }

  getTotalSectionAmountComputed(groupedBreakdown: Breakdown[]): number {
    return groupedBreakdown?.reduce(
      (totalAmount, breakdown) => totalAmount + breakdown.amount * (breakdown.isInbound ? 1 : -1),
      0,
    );
  }

  isInbound(operation: Operation, signedAmount: number, section: OperationSection): boolean {
    if ((signedAmount < 0 && !section.invertSign) || (signedAmount > 0 && section.invertSign)) {
      return !operation.isInbound;
    }
    return operation.isInbound;
  }

  isSalesChannelEcommerceOperation(operation: Operation): boolean {
    return operation.type === 'dispatch:ecommerce:salesChannel';
  }

  isEcommerceOperation(operation: Operation): boolean {
    return operation.type === 'dispatch:ecommerce' || operation.type === 'dispatch:ecommerce:salesChannel';
  }

  createUpdatedOperationOnCategoryChange(
    operation: Operation,
    mainBreakdown: Breakdown,
    category: Category,
  ): Operation {
    return {
      ...operation,
      breakdowns: operation.breakdowns.map((b) =>
        b.id === mainBreakdown?.id
          ? {
              ...mainBreakdown,
              categoryId: category.id,
              categoryWording: category.wording,
            }
          : b,
      ),
    };
  }

  createUpdatedOperationOnAmountChange(
    operation: Operation,
    mainBreakdown: Breakdown,
    amount: number,
    isInbound: boolean,
  ): Operation {
    return {
      ...operation,
      breakdowns: operation.breakdowns.map((b) =>
        b.id === mainBreakdown?.id
          ? {
              ...mainBreakdown,
              amount,
              isInbound,
            }
          : b,
      ),
    };
  }

  getSalesInvoiceSourceDocumentAttachments(operation: Operation): SourceDocumentAttachment[] {
    return (
      operation?.sourceDocumentAttachments?.filter(
        (sourceDocumentAttachments) =>
          sourceDocumentAttachments.sourceDocument.type === SourceDocumentType.SALES_INVOICE,
      ) || []
    );
  }

  getVendorInvoiceSourceDocumentAttachments(operation: Operation): SourceDocumentAttachment[] {
    return (
      operation?.sourceDocumentAttachments?.filter(
        (sourceDocumentAttachments) =>
          sourceDocumentAttachments.sourceDocument.type === SourceDocumentType.VENDOR_INVOICE,
      ) || []
    );
  }

  hasInvoiceSourceDocumentAttachments(operation: Operation): boolean {
    return (
      !!this.getSalesInvoiceSourceDocumentAttachments(operation)?.length ||
      !!this.getVendorInvoiceSourceDocumentAttachments(operation)?.length
    );
  }
}
