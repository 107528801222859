import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Operation, OperationPost } from '@dougs/operations/dto';
import { AbstractAccountingOperationsStateService } from './abstract-accounting-operations.state.service';

@Injectable({
  providedIn: 'root',
})
export class NotValidatedOperationsStateService extends AbstractAccountingOperationsStateService {
  constructor() {
    super();

    this.operationsEventsService.validateOperation$.subscribe((operation: Operation) => {
      if (!operation.validated && !operation.needsAttention) {
        this.addOperationState(operation);
      } else if (operation.validated || operation.needsAttention) {
        this.removeOperationState(operation);
      }
    });
  }

  protected validated = false;
  protected needsAttention = false;

  async createManualOperation(companyId: number, operation: OperationPost): Promise<void> {
    try {
      const createdManualOperation: Operation = await lastValueFrom(
        this.operationHttpService.createManualOperation(companyId, operation),
      );
      this.setState({
        operations: [...(this.state?.operations ?? []), createdManualOperation].sort((a, b) =>
          this.sortOperations(a, b),
        ),
      });
      await this.refreshOperationsCount(companyId);
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.showErrorMessage(e);
      }
      this.logger.error(e);
    }
  }

  protected shouldOperationBeAddedToState(operation: Operation): boolean {
    return !operation.validated && !operation.needsAttention;
  }
}
