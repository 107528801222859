import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { AssociationSlotCandidates } from '@dougs/operations/dto';

@Injectable({
  providedIn: 'root',
})
export class BreakdownHttpService {
  constructor(private readonly http: HttpClient) {}

  getBreakdownAssociationCandidates(
    companyId: number,
    operationId: number,
    breakdownId: number,
    associationName: string,
    slotName: string,
    offset = 0,
    search?: string,
  ): Observable<AssociationSlotCandidates> {
    let params = new HttpParams().append('offset', offset);

    if (search) {
      params = params.append('q', search);
    }

    return this.http.get<AssociationSlotCandidates>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/${operationId}/breakdowns/${breakdownId}/associations/${associationName}/slots/${slotName}/candidates`,
      {
        params,
      },
    );
  }
}
