import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { JobService } from '@dougs/core/job';
import { Job } from '@dougs/core/job-dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { AccountingSearch } from '@dougs/operations/dto';
import { OperationHttpService } from '../http/operation.http';

@Injectable({ providedIn: 'root' })
export class EcommerceOperationsState extends StateService<void> {
  constructor(
    private readonly logger: LoggerService,
    private readonly operationHttpService: OperationHttpService,
    private readonly jobService: JobService,
  ) {
    super();
  }

  async validateEcommerceCollectionOperations(companyId: number, search: AccountingSearch): Promise<Job | null> {
    try {
      const job: Job = await lastValueFrom(
        this.operationHttpService.validateEcommerceCollectionOperations(companyId, search),
      );
      const jobExecuted: Job = await lastValueFrom(this.jobService.handleJob(job));

      return jobExecuted;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }
}
