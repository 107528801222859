export interface AccountingLine {
  id: number;
  companyId: number;
  operationId: number;
  journal: string;
  accountNumber: string;
  wording: string;
  memo: string;
  note: string;
  amount: number;
  isCredit: boolean;
  isCounterpart: boolean;
  categoryId: number;
  date: string;
  validated: boolean;
  cumulative: number;
  fileCount: number;
  isLocked: boolean;
  isLockedByDate: string;
  type: string;
  associationDescriptions?: { label: string }[][];
  lettering?: string;
  statusPill?: STATUS_PILL;
}

export enum STATUS_PILL {
  LETTERED = 'lettered',
  PRE_LETTERED = 'pre-lettered',
}
