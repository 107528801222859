import { Currency } from '@dougs/synchronized-accounts/dto';
import { Spouse } from '@dougs/user/dto';
import { Operation } from '../dto';

const getDefaults = (): Operation => ({
  id: 1,
  validated: false,
  date: '',
  amount: 0,
  companyId: 1,
  needsAttention: false,
  isInbound: true,
  name: '',
  metadata: {
    paidAt: new Date('2021-09-29'),
    allowedJournals: [],
    isReversable: false,
    journal: '',
    status: '',
    hasStockManagementSoftware: false,
    isOperationInvalid: false,
  },
  isInvoice: false,
  autogenerated: false,
  breakdowns: [],
  isDispatch: false,
  transaction: {
    accountId: 1,
    deleted: false,
    date: '',
    amount: 0,
    metadata: {
      plan: '',
    },
    isManual: false,
    releaseDate: new Date('2021-09-29'),
    originalCurrency: Currency.EUR,
    originalChangeRate: 0,
    originalAmount: 0,
    changeRate: 0,
    currency: Currency.EUR,
    externalId: 1,
    isDeferred: false,
    providerId: 1,
  },
  isMiscellaneous: false,
  isDraft: false,
  memo: '',
  type: '',
  excluded: false,
  vatRate: 0,
  isBank: false,
  isAccountingSurveyMiscellaneous: false,
  isCashPayment: false,
  isVendorInvoice: false,
  isInvoiceSupplier: false,
  isExpense: false,
  isKilometricIndemnity: false,
  isCustomerEditableMiscellaneous: false,
  lockedByDate: false,
  manuallyLocked: false,
  wording: '',
  createdAt: new Date('2021-09-29'),
  creator: {
    profile: {
      id: 1,
      avatarUrl: '',
      fullName: '',
      alternateAvatarUrl: '',
      lastName: '',
      firstName: '',
      phone: '',
      email: '',
      normalizedPhones: [],
      workPhone: '',
      isMan: false,
      identityProof: [],
      spouse: {} as Spouse,
      initials: '',
    },
  },
  accountingLines: [],
  accountingSurveyId: 0,
  allowUnbalanced: false,
  creatorId: 1,
  deleted: false,
  detachDeleted: false,
  flag: '',
  hasVat: false,
  investmentId: 1,
  isReconciliation: false,
  isTotalAmountValid: false,
  message: '',
  updatedAt: '',
  messenger: {
    profile: {
      id: 1,
      avatarUrl: '',
      fullName: '',
      alternateAvatarUrl: '',
      lastName: '',
      firstName: '',
      phone: '',
      email: '',
      normalizedPhones: [],
      workPhone: '',
      isMan: false,
      identityProof: [],
      spouse: {} as Spouse,
      initials: '',
    },
  },
  messengerId: 1,
  note: '',
  sections: [],
  totalAmount: 0,
  transactionId: 0,
  validatedAt: '',
  vatAmount: 0,
  exclusionReason: '',
  description: '',
  sourceDocumentAttachments: [],
});

export const getOperationMock = (p?: Partial<Operation>): Operation => ({
  ...getDefaults(),
  ...p,
});
