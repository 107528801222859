import { lastValueFrom, Observable } from 'rxjs';
import { AccountingSurvey } from '@dougs/accounting-survey/dto';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingSearch, Operation } from '@dougs/operations/dto';
import { AbstractOperationsStateService } from './abstract-operations-state.service';

export abstract class AbstractAccountingSurveyOperationsStateService extends AbstractOperationsStateService<{
  currentOperation: Operation | null;
}> {
  protected constructor() {
    super();

    this.operationsEventsService.validateOperation$.subscribe((operation: Operation) => {
      this.updateOperationState(operation);
    });
  }

  public accountingSurvey?: AccountingSurvey;

  public currentOperation$: Observable<Operation | null> = this.select((state) => state.currentOperation);

  async refreshOperations(accountingSurvey: AccountingSurvey, accountingYear: AccountingYear): Promise<Operation[]> {
    try {
      this.OFFSET = 0;
      const search: AccountingSearch = this.getOperationSearch(accountingSurvey, accountingYear);
      const operations: Operation[] = await lastValueFrom(
        this.operationHttpService.getOperations(
          accountingSurvey.companyId,
          this.LIMIT,
          this.OFFSET,
          false,
          false,
          search,
          true,
        ),
      );
      this.setState({
        operations,
      });
      return operations;
    } catch (e) {
      this.logger.error(e);
      return this.state?.operations ?? [];
    }
  }

  async loadMoreOperations(accountingSurvey: AccountingSurvey, accountingYear: AccountingYear): Promise<void> {
    try {
      this.OFFSET += 1;
      const search: AccountingSearch = this.getOperationSearch(accountingSurvey, accountingYear);
      const newOperations: Operation[] = await lastValueFrom(
        this.operationHttpService.getOperations(
          accountingSurvey.companyId,
          this.LIMIT,
          this.OFFSET * this.LIMIT,
          false,
          false,
          search,
          true,
        ),
      );
      this.setState({
        operations: [...this.state.operations, ...newOperations],
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  public updateCurrentOperationState(operation: Operation): void {
    this.setState({
      currentOperation: operation,
    });
  }

  public updateOperationState(operation: Operation): void {
    super.updateOperationState(operation);
    if (this.state?.currentOperation?.id === operation?.id) {
      this.setState({
        currentOperation: operation,
      });
    }
  }

  protected removeOperationState(operation: Operation): void {
    super.removeOperationState(operation);
    if (this.state?.currentOperation?.id === operation?.id) {
      this.setState({
        currentOperation: null,
      });
    }
  }

  public addOperationState(operation: Operation): void {
    super.addOperationState(operation);
  }

  protected abstract getOperationSearch(
    accountingSurvey: AccountingSurvey,
    accountingYear: AccountingYear,
  ): AccountingSearch;
}
