import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Operation } from '@dougs/operations/dto';
import { AbstractAccountingOperationsStateService } from './abstract-accounting-operations.state.service';

@Injectable({
  providedIn: 'root',
})
export class IncomingOperationsStateService extends AbstractAccountingOperationsStateService {
  protected needsAttention = false;
  protected validated = false;

  protected shouldOperationBeAddedToState(operation: Operation): boolean {
    return false;
  }

  async refreshOperations(companyId: number): Promise<void> {
    try {
      const operations: Operation[] = await lastValueFrom(
        this.operationHttpService.getEcommerceIncomingSalesOperations(companyId),
      );
      this.setState({
        operations,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
