import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { AccountingSearch, AccountingStats } from '@dougs/operations/dto';
import { SearchService } from '../services/search.service';

// TODO Replace it in performance module when available
@Injectable({
  providedIn: 'root',
})
export class PerformanceHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly searchService: SearchService,
  ) {}

  saveSearch(companyId: number, accountingSearch: AccountingSearch): Observable<unknown> {
    return this.http.post<unknown>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-searches`,
      this.searchService.buildSaveSearch(accountingSearch),
    );
  }

  getOperationsStats(companyId: number, search?: Partial<AccountingSearch>): Observable<AccountingStats> {
    let params: HttpParams = new HttpParams().append('stats', 'true');

    if (search) {
      params = params.appendAll(this.searchService.buildSearchHttpParams(search));
    }

    return this.http.get<AccountingStats>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations`,
      {
        params,
      },
    );
  }
}
