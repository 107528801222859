import { ChangeHistory } from '../dto';

const getDefaults = (): ChangeHistory => ({
  id: 1,
  modelId: 1,
  companyId: 1,
  createdAt: '',
  current: {},
  metadata: {},
  modelName: '',
  user: {
    profile: {
      fullName: '',
      alternateAvatarUrl: '',
      avatarUrl: '',
    },
  },
  previous: {},
  updatedAt: '',
  userId: 1,
});

export const getChangeHistoryMock = (p?: Partial<ChangeHistory>): ChangeHistory => ({
  ...getDefaults(),
  ...p,
});
