import { CategoryGroup } from '../dto/category.dto';

const getDefaults = (): CategoryGroup => ({
  id: 1,
  name: '',
  color: '',
});

export const getCategoryGroupMock = (p?: Partial<CategoryGroup>): CategoryGroup => ({
  ...getDefaults(),
  ...p,
});
