import { lastValueFrom, Observable } from 'rxjs';
import { Operation } from '@dougs/operations/dto';
import { AbstractOperationsStateService } from './abstract-operations-state.service';

export abstract class AbstractAccountingOperationsStateService extends AbstractOperationsStateService<{
  count: number;
}> {
  protected constructor() {
    super();
  }

  protected abstract validated: boolean;
  protected abstract needsAttention: boolean;

  public operationsCount$: Observable<number> = this.select((state) => state.count);

  async refreshOperations(companyId: number): Promise<void> {
    this.OFFSET = 0;
    try {
      const operations: Operation[] = await lastValueFrom(
        this.operationHttpService.getOperations(
          companyId,
          this.LIMIT,
          this.OFFSET,
          this.validated,
          this.needsAttention,
          this.search,
        ),
      );
      this.setState({
        operations,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  public async loadMoreOperations(companyId: number): Promise<void> {
    if (!this.isValidatingOperation) {
      this.incrementOffset();
      const stateOperations: Operation[] = this.state.operations ?? [];
      try {
        const newOperations: Operation[] = await lastValueFrom(
          this.operationHttpService.getOperations(
            companyId,
            this.LIMIT,
            this.OFFSET * this.LIMIT,
            this.validated,
            this.needsAttention,
            this.search,
          ),
        );
        this.setState({
          operations: [
            ...stateOperations.filter(
              (stateOperation) => !newOperations.some((newOperation) => stateOperation.id === newOperation.id),
            ),
            ...newOperations,
          ],
        });
      } catch (e) {
        this.logger.error(e);
      }
    }
  }

  async refreshOperationsCount(companyId: number): Promise<void> {
    try {
      this.setState({
        count: await lastValueFrom(this.operationHttpService.getOperationCount(companyId, this.validated)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  protected removeOperationState(operation: Operation): void {
    super.removeOperationState(operation);
    void this.refreshOperationsCount(operation.companyId);
  }

  protected addOperationState(operation: Operation): void {
    super.addOperationState(operation);
    void this.refreshOperationsCount(operation.companyId);
  }
}
