import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Operation } from '@dougs/operations/dto';

@Injectable({
  providedIn: 'root',
})
export class OperationsEventsService {
  private readonly updateOperationSubject: Subject<Operation> = new Subject<Operation>();
  public updateOperation$: Observable<Operation> = this.updateOperationSubject.asObservable();

  private readonly addOperationSubject: Subject<Operation> = new Subject<Operation>();
  public addOperation$: Observable<Operation> = this.addOperationSubject.asObservable();

  private readonly removeOperationSubject: Subject<Operation> = new Subject<Operation>();
  public removeOperation$: Observable<Operation> = this.removeOperationSubject.asObservable();

  private readonly validateOperationSubject: Subject<Operation> = new Subject<Operation>();
  public validateOperation$: Observable<Operation> = this.validateOperationSubject.asObservable();

  public propagateUpdateOperation(operation: Operation): void {
    this.updateOperationSubject.next(operation);
  }

  public propagateAddOperation(operation: Operation): void {
    this.addOperationSubject.next(operation);
  }

  public propagateRemoveOperation(operation: Operation): void {
    this.removeOperationSubject.next(operation);
  }

  public propagateValidateOperation(operation: Operation): void {
    this.validateOperationSubject.next(operation);
  }
}
