import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AppConfig } from '@dougs/core/config';
import { Category } from '@dougs/operations/dto';
import { CategoryCacheService } from '../cache/category-cache.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly categoryCacheService: CategoryCacheService,
  ) {}

  getAllCategories(companyId: number): Observable<Category[]> {
    let categories$: Observable<Category[]> | null = this.categoryCacheService.getValue(companyId);

    if (!categories$) {
      categories$ = this.http
        .get<Category[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/categories?full=true`)
        .pipe(shareReplay(1));

      this.categoryCacheService.setValue(categories$, companyId);
    }

    return categories$;
  }

  getOperationPreferredCategoriesId(companyId: number, breakdownId: number, operationId: number): Observable<number[]> {
    return this.http.get<number[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/${operationId}/breakdowns/${breakdownId}/preferred-categories`,
    );
  }

  getCategory(companyId: number, categoryId: number): Observable<Category> {
    return this.http.get<Category>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/categories/${categoryId}`,
    );
  }

  getCategoriesId(
    companyId: number,
    type: 'investment' | 'expense' | 'operation',
    operationDate?: string,
    isRefund = false,
    search?: string,
  ): Observable<number[]> {
    let params = new HttpParams().append('type', type);

    if (isRefund) {
      params = params.append('isRefund', isRefund.toString());
    }

    if (operationDate) {
      params = params.append('operationDate', operationDate);
    }

    if (search) {
      params = params.append('search', search);
    }

    return this.http.get<number[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/categories`, {
      params,
    });
  }

  getOperationAvailableCategoriesId(
    companyId: number,
    breakdownId: number,
    operationId: number,
    isRefund: boolean,
    search: string,
  ): Observable<number[]> {
    let params = new HttpParams();

    if (isRefund) {
      params = params.append('isRefund', isRefund.toString());
    }

    if (search) {
      params = params.append('search', search);
    }

    return this.http.get<number[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/${operationId}/breakdowns/${breakdownId}/available-categories`,
      {
        params,
      },
    );
  }
}
