import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { AccountingSearch, AccountingStats } from '@dougs/operations/dto';
import { PerformanceHttpService } from '../http/performance.http';

@Injectable({
  providedIn: 'root',
})
export class AccountingStatsStateService extends StateService<AccountingStats> {
  constructor(
    private readonly performanceHttpService: PerformanceHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  public readonly accountingStats$: Observable<AccountingStats> = this.select((state) => state);

  async refreshAccountingStats(companyId: number, search: AccountingSearch): Promise<void> {
    try {
      this.setState(await lastValueFrom(this.performanceHttpService.getOperationsStats(companyId, search)));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async saveSearchInPerformance(companyId: number, search: AccountingSearch): Promise<boolean> {
    try {
      if (search) {
        await lastValueFrom(this.performanceHttpService.saveSearch(companyId, search));
        return true;
      }
      return false;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }
}
