import { AccountingSearch } from '../dto';

const getDefaults = (): AccountingSearch => ({
  search: 'search',
  accountIds: [],
  isNotInbound: false,
  isInbound: true,
  all: true,
  excludedOrDeleted: false,
  autogenerated: false,
  date: '',
  amount: '',
});

export const getAccountingSearchMock = (p?: Partial<AccountingSearch>): AccountingSearch => ({
  ...getDefaults(),
  ...p,
});
