import { OperationPost } from '../dto';

const getDefaults = (): OperationPost => ({
  date: '',
});

export const getOperationPostMock = (p?: Partial<OperationPost>): OperationPost => ({
  ...getDefaults(),
  ...p,
});
