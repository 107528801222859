import { Injectable } from '@angular/core';
import { AssociationSlot, Breakdown, Operation } from '@dougs/operations/dto';

@Injectable({
  providedIn: 'root',
})
export class AssociationService {
  updateAssociationDataToOperation(
    operation: Operation,
    breakdown: Breakdown,
    slot: AssociationSlot,
    value: unknown,
  ): Operation {
    return {
      ...operation,
      breakdowns: operation.breakdowns.map((breakdownIterated) =>
        breakdownIterated.id === breakdown.id
          ? {
              ...breakdownIterated,
              associationData: {
                ...breakdownIterated.associationData,
                [slot.key]: value,
              },
            }
          : breakdownIterated,
      ),
    };
  }
}
