import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { AssociationSlotCandidates } from '@dougs/operations/dto';
import { BreakdownHttpService } from '../http/breakdown.http';

@Injectable({
  providedIn: 'root',
})
export class OperationAssociationStateService extends StateService<void> {
  constructor(
    private readonly breakdownHttpService: BreakdownHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async loadMoreCandidates(
    companyId: number,
    operationId: number,
    breakdownId: number,
    associationName: string,
    slotName: string,
    offset: number,
    search?: string,
  ): Promise<AssociationSlotCandidates | null> {
    try {
      return await lastValueFrom(
        this.breakdownHttpService.getBreakdownAssociationCandidates(
          companyId,
          operationId,
          breakdownId,
          associationName,
          slotName,
          offset,
          search,
        ),
      );
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  getBreakdownAssociationCandidates(
    companyId: number,
    operationId: number,
    breakdownId: number,
    associationName: string,
    slotName: string,
    offset = 0,
    search?: string,
  ): Observable<AssociationSlotCandidates> {
    return this.breakdownHttpService.getBreakdownAssociationCandidates(
      companyId,
      operationId,
      breakdownId,
      associationName,
      slotName,
      offset,
      search,
    );
  }
}
